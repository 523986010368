@import url("https://fonts.googleapis.com/css?family=Rubik:500,400,700");

.webSitebody {
  height: 800px;
  font-family: Rubik;
  font-size: 70px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
  // flex: 30%;
  @media (max-width: 640px) {
    height: 200px;
  }

  .topLeft {
    display: flex;
    justify-content: right;
  }
  .firstBackround {
    position: absolute;
    width: 100%;
  }
  .section1 {
    display: flex;
    color: white;
    top: 167px;
    left: 226px;
    @media (max-width: 640px) {
      text-align: center;
      // justify-content: center;
    }
  }
  .header {
    line-height: 70px;
    width: 587px;
    font-size: 57px;
  }
  .headerTerms {
    line-height: 90px;
    width: 587px;
    font-size: 66px;
    @media (max-width: 640px) {
      width: unset;
      font-size: 20px;
      line-height: 9px;
      text-align: center;
    }
  }
  .rapper {
    top: 92px;
    position: relative;
    left: 203px;
    @media (max-width: 640px) {
      left: 0px;
      // top: 57px;
      // width: 267px;
      left: 20px;
      top: 50px;
      right: 8px;
    }
  }
  .rapperRtl {
    top: 92px;
    position: relative;
    left: 203px;
    @media (max-width: 640px) {
      right: 8px;
      top: 50px;
      width: 267px;
    }
  }
  .subheader {
    color: #4c4c4c;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    width: 500px;
  }
  .buttonSignUp {
    right: 50px;
    position: relative;
    cursor: pointer;
    border-color: transparent;
    color: white;
    background: #42c6a5;
    height: 45px;
    min-width: 120px;
    top: 15px;
    border-radius: 10px;
    padding: 13px, 33px, 13px, 33px;
  }
  //  .buttonLogin{
  //      right: 109px;
  //     position: relative;
  //         cursor: pointer;
  //         border-color: transparent;
  //         color: white;
  //         font-size: 16px;
  //         font-style: normal;
  //         font-weight: 600;
  //         line-height: 24px;
  //         letter-spacing: 0em;
  //         text-align: right;
  //         background: #FFFFFF;
  //         height: 24px;
  //         width: 39px;
  //         background-color: transparent;
  //         top: 25px;
  //         }
  .buttonSignUpTerms {
    right: 50px;
    position: relative;
    cursor: pointer;
    border-color: transparent;
    color: white;
    background: #42c6a5;
    height: 45px;
    min-width: 120px;
    border-radius: 10px;
    @media (max-width: 640px) {
      height: 28px;
      right: 6px;
      min-width: 70px;
      top: 9px;
    }
  }
  .buttonSignUpTermsRtl {
    left: 50px;
    position: relative;
    cursor: pointer;
    border-color: transparent;
    color: white;
    background: #42c6a5;
    height: 45px;
    min-width: 120px;
    border-radius: 10px;
    @media (max-width: 640px) {
      height: 28px;
      left: 6px;
      min-width: 70px;
      top: 9px;
    }
  }

  .buttonLoginTerms {
    right: 109px;
    position: relative;
    cursor: pointer;
    border-color: transparent;
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: right;
    background: #ffffff;
    height: 24px;
    width: 39px;
    background-color: transparent;
    top: 50px;
  }
}

.section1 .subheader {
  color: white;
  font-size: 23px;
  font-weight: lighter;
  line-height: 32px;
  width: 500px;
}
.section1 .subheaderTerms {
  color: white;
  font-size: 24px;
  font-weight: lighter;
  line-height: 80px;
  width: 500px;
  @media (max-width: 640px) {
    line-height: 20px;
    text-align: center;
    font-size: 14px;
    width: unset;
    margin-top: 15px;
  }
}
.LeftSide {
  // background-color: #f1f1f1;
  padding: 10px;
  flex: 30%;
  @media (max-width: 640px) {
    display: none;
    padding: 0px;
    flex: 0;
  }
}
.RightSide {
  // background-color: dodgerblue;
  padding: 10px;
  padding-left: 10%;
  flex: 65% 1;
  padding-right: 10%;
  @media (max-width: 640px) {
    margin-inline-start: 2vw;
    padding-left: unset;
    padding-right: unset;
  }
}
.RightSideRtl {
  direction: rtl;
}
.middle {
  // background-color: red;
  padding: 10px;
  flex: 5%;
  @media (max-width: 640px) {
    display: none;
    padding: 0px;
    flex: 0;
  }
}

.flex {
  display: flex;
  flex-direction: row;
}
.flexRtl {
  direction: rtl;
}
.flexArtical {
  display: flex;
  flex-direction: Row;
  padding: 15px;
  padding-left: 0;
}
.title {
  color: black;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  text-decoration: none;
  font-family: "Rubik";
  margin-left: 5px;
  margin-right: 0px;
}
.onClicksideTitle {
  border-color: #42c6a5;
  border-left-style: solid;
  color: black;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  text-decoration: none;
  font-family: "Rubik";
  margin-left: 5px;
  margin-right: 0px;
}
.sideTitle {
  color: black;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  text-decoration: none;
  font-family: "Rubik";
  margin-left: 5px;
  margin-right: 0px;
}
.titleRtl {
  text-align: right;
  margin-left: 5px;
  margin-right: 15px;
}
.artical {
  font-family: "Rubik";
  font-style: normal;
  // font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: start;
  margin-left: 5px;
  @media (max-width: 640px) {
    font-size: 15px;
  }
}
.articalRtl {
  font-family: "Rubik";
  font-style: normal;
  // font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: start;
  margin-right: 5px;
  margin-right: 5px;
  @media (max-width: 640px) {
    font-size: 15px;
  }
}

.round {
  border: 1px solid #a9b9ca;
  border-radius: 12px;
  padding: 25px;
  margin-left: 70px;
  position: sticky;
  top: 70px;
   width: 300px;
  margin-inline-start: 70px;
  top: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  @media (max-width: 640px) {
    display: none;
    width: 0px;
    padding: 0px;
    margin-left: 0px;
  }
}
.solid {
  border-top: 1px solid #a9b9ca;
}
.whatsApp {
  position: fixed;
  width: 45px;
  height: 45px;
  z-index: 70000;
  inset: auto 20px 70px auto;
  @media (max-width: 640px) {
    inset: auto 5px 45px auto;
  }
}
.imgwhatsApp:hover {
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
  transform: scale(1.1);
}
.imgwhatsApp {
  height: auto;
  max-width: 100%;
  transition: all 200ms ease-in-out;
  @media (max-width: 640px) {
    max-width: 60%;
  }
}
.rtlLanguage {
  direction: rtl;
  text-align: start;
  margin-left: 0px;
  margin-right: 9px;
  @media (max-width: 640px) {
    text-align: start;
  }
}
.buttom {
  display: inline;
  position: relative;
  top: 100px;
  left: 10%;
  width: 80%;
  @media only screen and(max-width:1245px) and(min-width:640px) {
    position: unset;
    display: inline;
    height: unset;
    margin-left: 10%;
    margin-top: 7%;
  }
  @media (max-width: 640px) {
    display: none;
  }
}
.buttomMedia {
  display: none;
  @media only screen and(max-width:1245px) and(min-width:640px) {
    display: none;
  }
  @media (max-width: 640px) {
    display: inline;
    height: 200px;
    width: 80%;
    position: unset;
    margin-top: 10%;
    margin-left: 11%;
  }
}
.section11 {
  position: relative;
  top: auto;
  width: 100%;
  height: unset;
  @media only screen and(max-width:1245px) and(min-width:640px) {
    position: relative;
    height: unset;
  }
  @media (max-width: 640px) {
    top: auto;
    position: relative;
    height: unset;
    width: 100%;
    margin: auto;
  }
}
.section11 .rapper1 {
  align-items: center;
  display: flex;
  top: auto;
  position: relative;
  left: 0px;
  width: 100%;
  text-align: center;
  flex-direction: column;
  top: -200px;
  @media only screen and (max-width: 1245px) and(min-width:640px) {
    top: -143px;
    margin-top: -10%;
  }
  @media (max-width: 640px) {
    top: 30%;
    position: absolute;
  }
}
.header1 {
  margin-bottom: 15px;
  line-height: 70px;
  width: 587px;
  font-size: 57px;
  font-weight: 500;
  font-family: "Rubik" !important;
  @media only screen and (max-width: 1245px) and(min-width:640px) {
    font-size: 45px;
    line-height: 59px;
    width: 457px;
  }
  @media (max-width: 640px) {
    // background: antiquewhite;
    width: unset;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
  }
}
.section11 .header1 {
  color: white;
  position: relative;
  width: 751px;
  font-size: 39px;
  top: auto;
  @media (max-width: 640px) {
    font-size: 19px;
    top: auto;
    width: 190px;
    line-height: 25px;
    // height: 233px;
  }
}
.smallButton1 {
  cursor: pointer;
  background-color: #42c6a5;
  height: 48px;
  width: 280px;
  left: 290px;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border: none;
  @media (max-width: 640px) {
    height: 35px;
    width: 197px;
  }
}
.section11 .smallButton1 {
  position: unset;
  @media (max-width: 640px) {
    top: auto;
    width: 178px;
  }
}
.logoButton {
  left: 50px;
  position: relative;
  width: fit-content;
  cursor: pointer;
  @media (max-width: 640px) {
    display: none;
  }
}
.logoButtonRtl {
  right: 50px;
  position: relative;
  width: fit-content;
  cursor: pointer;
  @media (max-width: 640px) {
    display: none;
  }
}
.section0 {
  display: flex;
  text-align: start;
  align-items: center;
  padding-top: 20px;
}
.space {
  flex-grow: 5;
}
.logoPhonedo {
  display: none;
  @media (max-width: 640px) {
    display: inline;
    position: absolute;
    z-index: 2000;
    height: 6%;
    margin-top: 2%;
    margin-left: 3%;
    margin-right: 3%;
  }
}
.rtllogoPhonedo {
  display: none;
  @media (max-width: 640px) {
    display: inline;
    position: absolute;
    z-index: 2000;
    height: 6%;
    margin-top: 2%;
    margin-left: 84%;
    margin-right: 3%;
  }
}
