@import url("https://fonts.googleapis.com/css?family=Rubik:500,400,700");



.mainBluePage {
    background-color: #232430;
    height: 100vh;
    margin-inline-start: -201px;
    overflow-x: auto;
}

.FlexQuestion {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.flex {
    display: flex;
    flex-direction: row;
    grid-gap: 100px;
    gap: 100px;
    height: 88vh;
}
.flexWithButton{
    display: flex;
    flex-direction: column;
}

.flexButton{
    display: flex;
    flex-direction: row;
    display: flex;
    height: 100px;
    position: relative;
    justify-content: center;
    margin-inline-start: 94vh;
    gap: 37vh;
}
.registrationBody {
    font-family: Rubik;
    text-align: left;
    display: flex;

    @media (max-width:640px) {
        height: 100vh;
    }
}

.bottomBarRegistrationButton {
    height: 54.47px;
    width: 179.31px;
    border-radius: 8px;
    //border: 1px solid black;
    // background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Rubik';
    font-style: normal;
    align-items: center;
    text-align: center;
    color: white;


    @media (max-width:640px) {
        font-size: 12px;
        height: 35px;
        width: 90px;
    }
}

.bottomBarRegistration {
    text-align: start;
    @media (max-width:640px) {
        width: 95%;
        margin-inline-start: -1360px;
        margin-top: 438px;
    }
}

.bottomBarRegistrationSwich {
    padding: 0px 0px 0px 1606px;
    display: flex;
    margin-top: -136px;
    margin-inline-start: -530px;
    text-align: start;

    //box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.15);
    @media (max-width:640px) {
        margin-inline-start: -1361px;
        margin-top: 255px;

    }
}

.bottomBarRegistrationButtonNext {
    height: 54.47px;
    width: 179.31px;
    border-radius: 8px;
    background-color: transparent;
    font-size: 16px;
    font-weight: 500;
    font-family: "Rubik";
    font-style: normal;
    align-items: center;
    text-align: center;
    border: 1px solid rgb(255, 255, 255);



    @media (max-width:640px) {
        font-size: 12px;
        height: 35px;
        width: 90px;
    }
}

.recordButtongap {
    display: flex;
    flex-direction: row;
    gap: 21px;
    margin-inline-start: 21px;

    @media (max-width:640px) {
        margin-inline-start: 8px;
        grid-gap: 0px;
        gap: 5px;
        width: 55px;
    }

}

.bottomBarRegistrationNext {
    display: flex; 
    text-align: start;
    @media (max-width:640px) {
        margin-inline-start: -1308px;
        margin-top: -37px;
    }
}

.bottomBarRegistrationNextSwich {
    padding: 0px 0px 0px 1756px;
    display: flex;
    margin-top: -51px;
    text-align: start;

    @media (max-width:640px) {
        margin-inline-start: -1308px;
        margin-top: -37px;
    }
}

.registrationBodyRtl {
    direction: rtl;
    font-family: Rubik;
    text-align: left;
    display: flex;

    @media (max-width:640px) {
        height: 100vh;
    }
}



.smallWebcam {
    object-fit: cover;
    background-color: transparent;
    background-blend-mode: lighten;
    position: relative;
    width: 68%;
    height: 319.55px;
    border: 3px solid #ffffff;
    border-radius: 12px;
    margin-top: -590px;
    @media (max-width:640px) {
        z-index: 999;
        position: absolute;
        top: 35px;
        right: 21px;
        height: 91px;
        width: 90px;
        border: 1px solid #98A9BC;
        border-radius: 8px;
    }
}


.bigWebcam {

    object-fit: cover;
    position: static;
    background-color: transparent;
    background-blend-mode: lighten;
    position: relative;
    width: 504.48px;
    height: 356.55px;
    border: 2px solid #ffffff;
    border-radius: 12px;

    @media (max-width:640px) {
        width: 294px;
        height: 309px;
        margin-inline-start: 237px;
        margin-top: 42px;
    }
}


.bigVideo {
    background-color: transparent;
    background-blend-mode: lighten;
    position: relative;
    width: 44%;
    height: 599.71px;
    border: 4px solid #ffffff;
    border-radius: 12px;
    margin-inline-start: 51vh;
    top: 10vh;
    @media (max-width:640px) {
        width: 294px;
        height: 291px;
        margin-inline-start: 237px;
        margin-top: -109px;
    }
}


.smallVideo {


    position: static;
    background-color: transparent;
    background-blend-mode: lighten;
    position: relative;
    max-width: 50%;
    height: 50vh;
    border: 2px solid #ffffff;
    border-radius: 12px;

    @media (max-width:640px) {
        z-index: 999;
        position: absolute;
        top: 35px;
        right: 24px;
        height: 91px;
        width: 90px;
        border: 1px solid #98A9BC;
        border-radius: 8px;
    }
}

.question {
    background-color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 12px;
    width: 64%;
    height: 170px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    margin-top: 490px;
    @media (max-width:640px) {
        margin-inline-start: 240px;
        width: 289px;
        height: 188px;
        margin-top: -161px;

    }
}

.questionText {
    color: black;
    z-index: 1000;
    width: 38vh;
    top: 568px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;

    @media (max-width:640px) {
        margin-inline-start: 248px;
        font-size: 17px;
        width: 38vh;
        margin-top: -174px;

    }
}