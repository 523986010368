@import url("https://fonts.googleapis.com/css?family=Rubik:500,400,700");

.radioDiv {
    display: flex;
    padding-top: 26px;

    @media (max-width:640px) {
        display: unset;
    }
}

.radioItem {
    position: relative;
    padding-top: 12px;
    padding-bottom: 17px;
    padding-inline-start: 23px;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    width: 44%;
    max-width: 335px;
    margin-inline-end: 25px;

    @media (max-width:640px) {
        padding-inline-start: 10px;
        padding-top: 5px;
        padding-bottom: 10px;
        margin-inline-end: 5%;
        width: 42%;
        max-width: 151px;
        margin-top: 3%;
        text-align: start;
    }
}

.radioItem input[type='radio'] {
    display: none;
}

.radioItem label {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    height: 22px;

    @media (max-width:640px) {
        font-size: 12px;
    }
}

.radioItem label:before {
    content: " ";
    display: inline-block;
    position: relative;
    width: 22px;
    height: 22px;
    border-radius: 13px;
    border: 1px solid #A9B9CA;
    background-color: transparent;
    margin-inline-end: 24px;
    top: 5px;

    @media (max-width:640px) {
        width: 14px;
        height: 14px;
        margin-inline-end: 12px;
    }
}

.radioItem input[type=radio]:checked+label:before {
    content: " ";
    // display: inline-block;
    // position: relative;
    width: 22px;
    height: 22px;
    border-radius: 13px;
    border: 1px solid #42C6A5;
    margin-inline-end: 24px;

    @media (max-width:640px) {
        width: 14px;
        height: 14px;
        margin-inline-end: 12px;
    }
}

.radioItem input[type=radio]:checked+label:after {
    border-radius: 6px;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 29px;
    right: 29px;
    top: 23px;
    content: " ";
    background: #42C6A5;
    direction: ltr;

    @media (max-width:640px) {
        border-radius: 4px;
        width: 8px;
        height: 8px;
        left: 14px;
        right: 14px;
        top: 14px;
    }
}