@import url("https://fonts.googleapis.com/css?family=Rubik:500,400,700");

.registrationBody {
    font-family: Rubik;
    text-align: left;
    display: flex;

    @media (max-width:640px) {
        height: 100vh;
    }
}

.registrationBodyRtl {
    direction: rtl;
    font-family: Rubik;
    text-align: left;
    display: flex;

    @media (max-width:640px) {
        height: 100vh;
    }
}

.registrationBodyRtl {
    direction: rtl;
    font-family: Rubik;
    text-align: left;
    display: flex;
}



.header {
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 20px;

    @media (max-width:640px) {
        font-size: 26px;
    }
}

.bigHeader {
    font-weight: 500;
    font-size: 48px;
    line-height: 55px;
    margin-bottom: 44px;

    @media (max-width:640px) {
        font-size: 26px;
        margin-bottom: 20px;
    }
}

.subHeader {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #A9B9CA;
    margin-bottom: 38px;
    width: 90%;
}

.otpInput input {
    width: 55px !important;
    height: 55px !important;
    background: #EBF3EF;
    border-radius: 10px;
    margin-right: 0.8vw;
    border: none;
    font-size: 24px;

    @media (max-width:640px) {
        width: 40px !important;
        height: 40px !important;
        font-size: 14px;
    }

    // border:1px solid #42C6A5;
}

.otpInputValid input {
    border: 1px solid #42C6A5 !important;
}

.otpInputInvalid input {
    border: 1px solid #FC2626 !important;
}

.otpInput span {
    display: none;
}

.inputGreen {
    color: #42C6A5;
    cursor: pointer;
}

.buttonCreate {
    height: 48px;
    width: 416px;
    border-radius: 8px;
    background: #EBF3EF;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 500;
    margin-top: 4vh;

    @media (max-width:640px) {
        height: 40px;
        width: 250px;
        font-size: 14px;
    }
}

.smallButtonCreate {
    height: 48px;
    width: 110px;
    border-radius: 8px;
    background: #42C6A5;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    bottom: 40px;
    right: 50px;
}

#buttonCreateApprove {
    background: #42C6A5;
}

#buttonCreateDisable {
    background: #ebf3ef;
}

.formArea {
    display: flex;
    // text-align: center;
}

.formAreartl {
    justify-content: right !important;
    padding-right: 8vw !important;
}

.dataArea {
    padding: 100px 100px 10px 160px;

    @media (max-width:640px) {
        padding: 30px
    }
}

.bigdataArea {
    padding: 50px 100px 10px 160px;

    @media (max-width:640px) {
        padding: 30px
    }
}

.dataAreaCenter {
    padding: 180px;
    text-align: center;
}

.dataAreaCenRtl {
    padding: 50px;
    text-align: center;

    @media (max-width:640px) {
        padding: 15px;
    }
}

.dataAreaCenRtlNoPadding {
    padding: 50px;
    text-align: center;

    @media (max-width:640px) {
        padding: 0px;
    }
}

.divCenter {
    text-align: center;
    width: 420px;

    @media (max-width:640px) {
        width: 280px;
    }
}

.dataAreaWide {
    // flex: 80%;
    // padding: 50px 40px 5px 40px;
    flex: 98%;
    padding-bottom: 0px;
    padding-top: 25px;
    padding-left: 40px;
    padding-right: 40px;

    @media (max-width:640px) {
        padding: 20px 33px 1px 31px;
    }
}

.dataAreaSmallWide {
    padding: 2vh 2vw;
}

.banner {
    position: static;
    background-color: transparent;
    background-blend-mode: lighten;
    position: relative;
    max-width: 50%;
    height: 100vh;

    @media (max-width:640px) {
        display: none;
    }
}

.flexForm {
    display: flex;
    grid-column: 1;
    grid-row: 1;
}

.flexFormScroll {
    overflow-y: scroll;
    height: 50vh;
}

.flexForm .item {
    flex-grow: 5;
}

.flexForm .itemRight {
    text-align: center;
    margin-bottom: 2.5vh;
    margin-inline-start: 15px;
    //    @media (max-width:640px) {
    //     margin-inline-start: 0;
    //   }
}

.submitError {
    color: red;
    font-size: 14px;
    // margin-bottom: 2%;
    /* line-height: 19px; */
    width: 400px;

    @media (max-width:640px) {
        width: 250px;
        margin-bottom: 7%;
        line-height: 19px;
    }
}

// #banner1{
//     background-image:  url("../../images/banner1.png") ;
// }

// #banner2{
//     background-image:  url("../../images/banner2.png") ;
// }

// #banner3{
//     background-image:  url("../../images/banner3.png") ;
// }

// #banner4{
//     background-image:  url("../../images/banner4.png") ;
// }

// #banner5{
//     background-image:  url("../../images/banner5.png") ;
// }
// #banner6{
//     background-image:  url("../../images/banner6.png") ;
// }
// #banner7{
//     background-image:  url("../../images/banner7.png") ;
// }
// #banner8{
//     background-image:  url("../../images/banner8.png") ;
// }
// #banner9{
//     background-image:  url("../../images/banner9.png") ;
// }
// #banner10{
//     background-image:  url("../../images/banner10.png") ;
// }
// #banner11{
//     background-image:  url("../../images/banner11.png") ;
// }
// #banner12{
//     background-image:  url("../../images/banner12.png") ;
// }

.agentTypeCard {
    // height: 180px;
    // width: 330px;
    height: 28vh;
    height: unset;
    width: 20vw;
    border-radius: 0px;
    padding: 0% 2% 0% 2%;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
    border-radius: 15px;

    @media (max-width:640px) {
        height: 120px;
        width: 44vw;
        margin-right: 2px;
        padding-top: 6px;
    }
}

.agentTypeCardRtl {
    margin-right: 0;
    margin-left: 20px;

    @media (max-width:640px) {
        margin-left: 7px;
    }
}

.agentTypeCard .agentTypeCardArea {
    display: flex;
}

.agentTypeCardArea .item {
    flex-grow: 5;
}

.agentTypeCardArea .radioButton {
    margin-top: 4%;
    text-align: end
}

.agentTypeCardForm {
    display: flex;
}

.agentTypeCard .header {
    font-weight: 500;
    font-size: 16px;
    line-height: 55px;
    margin-bottom: 1vh;

    @media (max-width:640px) {
        font-size: 12px;
        margin-bottom: 0;
        line-height: 10px
    }
}

.agentTypeCard .subHeader {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #A9B9CA;
    padding: 3%;
    margin-bottom: 0;

    @media (max-width:640px) {
        font-size: 10px;
        margin-bottom: 0;
        line-height: 15px
    }
}

.checkIcon {
    @media (max-width:640px) {
        height: 14px;
    }
}

.agentTypeCard .agentTypeImg {
    margin-top: -9%;
    width: 18vw;

    // width: 100px;
    @media (max-width:640px) {
        width: 40px;
    }
}

.preferredWorkCard {
    // height: 160px;
    // width: 350px;
    height: 20vh;
    width: 20vw;
    border-radius: 8px;
    padding: 4%;
    // box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    margin-inline-end: 10%;
    background-color: #EBF3EF;
    margin-bottom: 7%;
    position: relative;

    @media (max-width:640px) {
        height: 130px;
        width: 45vw;
    }
}

.preferredWorkCard .preferredWorkCardArea {
    display: flex;
}

.preferredWorkCardArea .item {
    flex-grow: 5;
}

.preferredWorkCardArea .radioButton {
    margin-top: 4%;
    text-align: end;

    @media (max-width:640px) {
        height: 14px;
    }
}

.preferredWorkCardForm {
    display: flex;
}

.preferredWorkCard .header {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 2%;

    @media (max-width:640px) {
        font-size: 14px;
    }
}

.preferredWorkCard .subHeader {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #A9B9CA;
    padding: 3%;
}

.preferredWorkCard .img {
    position: absolute;
    bottom: 2vh;

    @media (max-width:640px) {
        height: 45px;
        right: 17px;
    }
}

.addIcon {
    margin-bottom: -10px;
    margin-inline-start: 10px;

    @media (max-width:640px) {
        height: 16px;
        margin: 0;
        margin-left: 10px;
        margin-right: 10px;
    }
}

.descriptionHeader {
    margin-bottom: 20px;
}

.descriptionSubHeader {
    font-family: Rubik;
    font-size: 16px;
    line-height: 24px;
    color: #A9B9CA;
    margin-bottom: 23px;
    width: 598px;
    height: auto;

    @media (max-width:640px) {
        font-size: 12px;
        width: 85%;
        margin: 20px 7.5%;
    }
}

.flexFormArea {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flexFormArea {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.formSide {
    display: flex;
    flex-direction: column;
    width: 90%;
    // width: 100%;
    position: relative;
    flex: 80;

    @media (max-width:640px) {
        text-align: center;
        align-items: center;
        width: 100%;
    }
}

.formSideRtl {
    text-align: right;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    flex: 80;

    @media (max-width:640px) {
        text-align: center;
        align-items: center;
    }
}

.dots2 {
    z-index: -2000;
    left: 60px;
    position: absolute;
    width: 90%;
    top: 34px;

    @media (max-width:640px) {
        left: 13px;
    }
}

.dots2Rtl {
    @media (max-width:640px) {
        left: 22px;
    }
}

.dots2-small {
    z-index: -2000;
    left: 70px;
    position: absolute;
    width: 75%;
    height: 90%;
    top: 34px;
}

.dots2-smallRtl {
    @media (max-width:640px) {
        left: 44px;
        top: -160px;
    }
}

.agentTypeHeader {
    margin-bottom: 20px;

    @media (max-width:640px) {
        font-size: 16px;
        margin-bottom: 10px;
    }
}

.savedCircle {
    background-color: #42C6A5;
    width: 50px;
    height: 41px;
    display: inline-block;
    border-radius: 60px;
    padding: 1em 0.7em;
    text-align: center;
}

.faCircle-border-icon {
    border-width: 1px;
    border-style: solid;
    border-color: #C5C7D1;
    border-radius: 50% 50% 50% 50%;
}

.faRegCircle-border-icon {
    border-width: 1px;
    border-style: solid;
    border-color: #cccccc;
    border-radius: 50% 50% 50% 50%;
    color: #ffffff;
    font-size: 34px;
    grid-area: overlap
}

.workplaceItem {
    position: relative;
    flex-grow: 5;
}

.dataAreaCenter .bigHeader {
    margin-bottom: 31px;

}

.sendAgain {
    margin-top: 55px;
}

.checkboxPadding {
    padding-inline-start: 16px;
}

.whatsApp {
    position: fixed;
    right: 14px;
    width: 63px;
    height: 63px;
    bottom: 66px;
    z-index: 70000;
}

.imgwhatsApp {
    height: auto;
    max-width: 100%;
}

.fieldLanguage {
    margin-bottom: 2.5vh;

    @media (max-width:640px) {
        margin-bottom: 0;
    }
}

.languageFields {
    margin-right: 0;

    @media (max-width:640px) {
        margin-right: 4px;
    }
}

.languageFieldsRtl {
    margin-left: 0;

    @media (max-width:640px) {
        margin-right: 4px;
    }
}

.faTrashAltIcon {
    margin-left: 1vw;
    cursor: pointer;

    @media (max-width:640px) {
        height: 16px;
    }
}

.paddingTitle {
    padding-inline-start: 15%;
    padding-inline-end: 15%;
    padding-top: 10%;
    text-align: center;

    @media (max-width:640px) {
        padding-inline-start: 13%;
        padding-inline-end: 13%;
    }
}

.deleteLanguageIcon {
    height: 35px;
    padding-top: 20px;
    line-height: 14px;
    text-align: start;
}

.checkboxMarging {
    padding-inline-end: 16px;
}

.subHeader2 {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #A9B9CA;

    @media (max-width:640px) {
        font-size: 12px;
    }
}

.paddingTitle2 {
    padding-inline-start: 15%;
    padding-inline-end: 15%;
    padding-top: 5%;
    text-align: center;
}

.selectCountry {
    width: 400px;
    min-height: 65px;
    font-size: 14px;


    @media (max-width:640px) {
        width: 250px;
        min-height: 46px;
        height: 46px;
        font-size: 12px;
        margin-bottom: 4vh;
    }
}
.fieldLanguage .selectCountry {
    width: 20vw;
    min-height: 65px;
    font-size: 14px;


    @media (max-width:640px) {
        width: 250px;
        min-height: 46px;
        height: 46px;
        font-size: 12px;
        margin-bottom: 4vh;
    }
}

.css-19m8w8e-Control {
    @media (max-width:640px) {
        width: 250px !important;
        height: 40px !important;
        margin-bottom: 5px;
        min-height: 45px;
    }
}

.marginLeftRtl {
    @media (max-width:640px) {
        margin-left: 5px;
    }
}

.css-vsdtd {
    @media (max-width:640px) {
        width: 250px !important;
        height: 40px !important;
        margin-bottom: 5px;
        min-height: 45px;
    }
}

.css-6j8wv5-Input {
    @media (max-width:640px) {
        font-size: 12px !important;
    }
}

.css-2613qy-menu {
    width: 250px !important;

    @media (max-width:640px) {
        width: 250px !important;
    }
}

.logoPhonedo {
    display: none;

    @media (max-width:640px) {
        display: inline;
        position: absolute;
        z-index: 2000;
        height: 6%;
        margin-top: 2%;
        margin-left: 3%;
        margin-right: 3%;
    }
}

.rtllogoPhonedo {
    display: none;

    @media (max-width:640px) {
        display: inline;
        position: absolute;
        z-index: 2000;
        height: 6%;
        margin-top: 2%;
        margin-left: 84%;
        margin-right: 3%;
    }
}

.logoButton {
    left: 50px;
    position: absolute;
    top: 30px;
    width: fit-content;
    cursor: pointer;

    @media (max-width:640px) {
        display: none;
    }
}

.logoButtonRtl {
    right: 50px;
    position: absolute;
    top: 30px;
    width: fit-content;
    cursor: pointer;

    @media (max-width:640px) {
        display: none;
    }
}









.timer {
    position: absolute;
    -webkit-margin-start: -359px;
    margin-inline-start: 51vh;
    margin-top: 3vh;
    font-weight: 500;
    color: #999999;
    display: flex;
    grid-gap: 6px;
    grid-gap: 6px;
    gap: 6px;
    border-bottom: 1px solid #999999;
    @media (max-width:640px) {
        left: 14px;
        top: 707px;
        margin-inline-start: 121px;
    }
}



.autorenew {
    display: none;

    @media (max-width:640px) {
        background-color: black;
        border: none;
        color: white;
        padding: 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        margin: 4px 2px;
        border-radius: 50%;
        z-index: 999;
        position: absolute;
        right: 20px;
        top: 107px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
