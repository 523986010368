.container {
    text-align: center;
}

.toggle-switch {
    position: relative;
    width: 44px;
    display: inline-block;
    text-align: start;
}

.toggle-switch .checkbox {
    display: none !important;
}

.label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
}

.inner {
    display: block;
    width: 200%;
    height: 24px;
    margin-inline-start: -100%;
    transition: margin 0.2s ease-in 0s;
}

.innerRtl {
    display: block;
    width: 200%;
    height: 24px;
    margin-inline-start: -100%;
    transition: margin 0.2s ease-in 0s;
}

.inner:before,
.inner:after {
    float: left;
    width: 50%;
    height: 24px;
    padding: 0;
    line-height: 36px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
}

.innerRtl:before,
.innerRtl:after {
    float: right;
    width: 50%;
    height: 24px;
    padding: 0;
    line-height: 36px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
}

.inner:before {
    content: "YES";
    background-color: #EBF3EF;
    color: #EBF3EF;
}

.inner:after {
    content: "NO";
    background-color: #CCCCCC;
    color: #CCCCCC;
}

.innerRtl:before {
    content: "YES";
    background-color: #EBF3EF;
    color: #EBF3EF;
}

.innerRtl:after {
    content: "NO";
    background-color: #CCCCCC;
    color: #CCCCCC;
}

.switch {
    display: block;
    width: 20px;
    height: 20px;
    margin-top: 2px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    background: #999999;
    position: absolute;
    top: 0;
    bottom: 0;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.6s ease-in 0s;
}

.switchRtl {
    display: block;
    width: 20px;
    height: 20px;
    margin-top: 2px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    background: #999999;
    position: absolute;
    top: 0;
    bottom: 0;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.6s ease-in 0s;
}

.checkbox:checked+.label .inner {
    margin-left: 0;
}

.checkbox:checked+.label .innerRtl {
    margin-right: 0;
}

.checkbox:checked+.label .switch {
    right: 0px;
}

.checkbox:checked+.label .switchRtl {
    left: 0px;
}