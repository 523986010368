@import url("https://fonts.googleapis.com/css?family=Rubik:500,400,700");

.topbar {
    background: #F5F3EE;
    padding-top: 14px;
    padding-bottom: 14px;
    width: 100vw;
    border-bottom: 1px solid #A9B9CA;
    text-align: start;
    position: fixed;
    z-index: 70000;
}
.bottombar{
    background: #F5F3EE;
    padding-top: 14px;
    padding-bottom: 14px;
    width: 100vw;
    height: 70px;
    border-top: 1px solid #A9B9CA;
    text-align: start;
    position: fixed;
    bottom: 0;
    z-index: 70000;
}
.topbarRtl {
    direction: rtl !important;
}
.bottombarRtl {
    direction: rtl !important;
}
.bottombar .signinBtn{
    background: #33354E;
    border-radius: 6px;
    color: white;
    width:120px ;
    height:48px ;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-inline-start: 10px;
}

.flexBetween {
    display: flex;
    justify-content: space-between;

    @media (max-width: 640px) {
        flex-direction: column;
        justify-content: space-between;

    }
}
.topbarMenu{
    z-index: 200000 !important;
    height: 100vh;
    width: 100vw;
    background-color:#F5F3EE ;
}
.topbarMenu .topbarbody{
    padding-top: 20vh;
    z-index: 0;
    cursor:pointer;
}
.topbar .flexBetween {
    @media (max-width: 640px) {
        flex-direction: unset;
        justify-content: space-between;

    }
}
.topLang{
    z-index: 1;
    position: absolute;
}
.topbar .menuIcon{
    margin-top: 8px;
    margin-inline-end: 15px;
}
.logo {
    margin-inline-start: 100px;

    @media (max-width: 640px) {
        margin-inline-start: 0px;
        width: 141px;
    }
}

.topbarRegisterButton {
    margin-inline-end: 100px;
    // margin-top: 12px;
    background: #33354E;
    border: none;
    border-radius: 6px;
    width: 130px;
    height: 48px;
    text-align: center;
    cursor: pointer;
}

.topbarRegisterButton .header {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.langMenuTitle {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #33354E;
    text-transform: capitalize;
    padding-inline-end: 15px;
}

.langMenuLabel {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #33354E;
    padding-inline-start: 8px;
}

.landingPagebody {
    font-family: 'Rubik' !important;
    font-size: 70px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
    background-color: #F5F3EE;
    overflow-x: hidden;

    @media (max-width:640px) {
        overflow-x: hidden;
    }
}

.section1 {
    // display: flex;
    color: white;
    top: 167px;
    left: 226px;

    @media (max-width:640px) {
        margin-top: 55px;
    }

}

.section1 .header {

    @media (max-width:640px) {
        margin-bottom: 30px;
    }

}

.section1 .playOrangeBtn {
    width: 36px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #33354E;
    box-sizing: border-box;
    border-radius: 50%;
    display: inline-block;
    margin-inline-start: 20px;
    display: flex;
    justify-content: center;
}

.section1 .watchVideoBtn {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #33354E;
    justify-content: center;
    margin-top: 40px;
}

.section1 .subheader {
    @media (max-width:640px) {
        color: #33354E;
    }
}

.section1 .sms {
    position: absolute;
    // left: 25vw;
    z-index: 400;
    top: 385px;

    @media (max-width:640px) {
        position: relative;
        left: 0vw;
        z-index: 400;
        height: 100px;
        top: -35vh;
    }
}

.section1 .smsRtl {
    position: absolute;
    left: 25vw;
    z-index: 400;
    top: 385px;

    @media (max-width:640px) {
        position: relative;
        left: 0vw;
        z-index: 400;
        height: 100px;
        top: -35vh;
    }
}

.section1 .image {
    @media (max-width:640px) {
        height: 473px;
        z-index: 2;
    }
}

.subsection {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    position: unset;
}

.section1 .subsection {
    @media (max-width:640px) {
        display: flex;
        flex-direction: column;
    }
}

.rtlLanguage {
    direction: rtl;
    text-align: start;
    margin-left: 0px;
}

.section1 .rapper {
    // padding-left: 290px;
    // padding-right: 0;
    z-index: 200;
    width: 55vw;

    @media (max-width:640px) {
        padding: 0px;
        margin: 70px 23px;
        margin-bottom: 0px;
        width: auto;
        text-align: center;
    }

    

}
.subheaderLine {
    color: #33354e;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 0px;
    cursor: pointer;
    text-decoration: underline;
    z-index: 2000;
    @media (max-width: 640px) {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 32px;
        width: 100%;
    }
}

.section1 .rapperRtl {
    z-index: 200;
    // padding: 0;
    width: 55vw;
    // padding-inline-start: 250px;

    @media (max-width:640px) {
        padding: 0px;
        margin: 70px 23px;
        margin-bottom: 0px;
        width: auto;
        padding-inline-start: 0px;
        text-align: center;
    }
}

.section1 .smallGreenButton {
    @media (max-width:640px) {
        margin-left: 0px;
        margin-right: 0px;
        width: 200px;
        height: 48px;
    }
}

.section1 .smallGreenButtonRtl {
    @media (max-width:640px) {
        margin-left: 0px;
        margin-right: 0px;
        width: 200px;
        height: 48px;

    }
}

.section1 .smallWhiteButton {
    direction: ltr !important;

    @media (max-width:640px) {
        margin-left: 0px;
        margin-right: 0px;
        width: 100%;
    }
}

.header {
    margin-bottom: 15px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 86px;
    letter-spacing: -1px;
    color: #33354E;

    @media (max-width:640px) {
        font-weight: 500;
        font-size: 48px;
        font-weight: 700;
        font-size: 40px;
        line-height: 55px;
        letter-spacing: -1px;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}

.subheader {
    color: #4C4C4C;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    width: 90%;
    margin-bottom: 0px;

    @media (max-width:640px) {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        width: 100%;
    }
}

.smallGreenButton {
    cursor: pointer;
    background-color: #42C6A5;
    height: 48px;
    width: 170px;
    left: 290px;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    border: none;
    margin-right: 20px;
    font-family: 'Rubik';
    font-style: normal;

    @media (max-width:640px) {
        width: 85vw;
    }
}

.smallGreenButtonRtl {
    margin-left: 20px;
    margin-right: 0px;

    @media (max-width:640px) {
        width: 85vw;
    }
}

.smallWhiteButton {
    cursor: pointer;
    background-color: white;
    height: 48px;
    width: 200px;
    left: 290px;
    border-radius: 8px;
    color: #33354E;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #33354E;
    font-family: 'Rubik';
    font-style: normal;

    @media (max-width:640px) {
        width: 85vw;
        position: relative;
        top: -18px;
        left: 0px;
    }
}

.section2 {
    text-align: center;
    width: 99vw;
}

.section2 .title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    color: #33354E;

    @media (max-width: 640px) {
        font-weight: 700;
        font-size: 40px;
        line-height: 55px;
        letter-spacing: -1px;
        color: #000000;
    }
}

::-webkit-scrollbar {
    display: none;
    width: 0;
}

.section2 .cardsLine {
    display: flex;
    margin: 40px 0px;
    overflow-x: scroll;
    overflow-y: hidden;
}

.section2 .card {
    position: relative;
    width: 175px;
    height: 85px;
    background: #E9E7E0;
    border: 1px solid #C5C7D1;
    box-sizing: border-box;
    border-radius: 14px;
    margin-inline-end: 4px;
    margin-inline-start: 4px;
    text-align: center;
}

.section2 .card .img {
    mix-blend-mode: multiply;
    margin: 15px 20px;
}

.section3 {
    display: flex;
    padding-top: 160px;

    @media (max-width:640px) {
        padding-top: 60px;
    }
}

.section3 .rapper {
    width: 40vw;
    margin-top: -30vh;

    @media (max-width:640px) {
        width: auto;
    }
}

.mediumHeader {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 55px;
    color: #000000;
    margin-bottom: 40px;
}

.mediumSubheader {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #4C4C4C;
    opacity: 0.7;
}

.rapper {
    // padding-left: 270px;
    // padding-right: 70px;
    // margin-top: -160px;
    margin-inline-start: 6vw;
    margin-inline-end: 1vw;
    margin-top: -35vh;

    @media (max-width:640px) {
        padding: 10px;
    }
}

.rapperRtl {
    margin-inline-start: 8vw;
}

.imgRtl {
    transform: scaleX(-1);
}

.imgSection3 {
    @media (max-width:640px) {
        width: 130vw;
        overflow-x: hidden;
        margin-inline-start: 0px;
    }
}

.section3 .subsection {
    position: relative;
    top: -50px;

    @media (max-width:640px) {
        flex-direction: column-reverse;
        align-items: unset;
        width: 100vw;
        top: 0px;
    }
}

.section3 .rapper {
    @media (max-width:640px) {
        padding: 0px;
        margin-top: 0px;
        top: 0px;
        width: auto;
        text-align: center;
    }
}

.section3 .rapperRtl {
    margin-top: -250px;

    @media (max-width:640px) {
        margin-inline-start: 6vw;
        margin-inline-end: 1vw;
        padding: 0px;
        margin-top: 0px;
        text-align: center;

    }
}

.section3 .mediumHeader {
    width: 90%;

    @media (max-width:640px) {
        width: 100%;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 55px;
        text-align: center;
        color: #000000;
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 0px;
        margin-top: 0px;
        margin-inline-start: -3vw;
    }
}

.section3 .mediumSubheader {
    width: 90%;

    @media (max-width:640px) {
        width: 95%;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #4C4C4C;
        padding-inline-start: 0px;
        padding-inline-end: 39px;
        margin-top: -130px;
    }
}

.section4 .imgSection3 {
    @media (max-width:640px) {
        width: 130vw;
        overflow-x: hidden;
        margin-inline-start: 20px;
    }
}

.section3 .smallGreenButton {
    @media (max-width:640px) {
        height: 65px;
        width: 276px;
        margin-inline-start: 0px;
    }
}

.section4 {
    height: 100vh;

    @media (max-width:640px) {
        min-height: 100vh;
        height: auto;
    }
}

.section4 .subsection {
    padding-inline-start: 200px;
    position: relative;
    top: 0px;

    @media (max-width:640px) {
        flex-direction: column;
        align-items: unset;
        width: 100vw;
        padding-left: 0px;
        top: 0px;
    }
}

.section4 .subsectionRtl {
    padding-left: 10px;
    padding-right: 270px;
    position: relative;
    top: 0px;

    @media (max-width:640px) {
        flex-direction: column;
        align-items: unset;
        width: 100vw;
        padding-left: 0px;
        padding-right: 0px;
        top: 0px;
    }
}

.section4 .rapper {
    padding: 0;
    margin-left: -225px;
    margin-top: 200px;
    padding-right: 65px;
    text-align: center;

    @media (max-width:640px) {
        padding: 0px;
        margin-top: 0px;
        margin-left: 0px;
        margin-inline-start: 6vw;
        margin-inline-end: 1vw;
    }
}

.section4 .rapperRtl {
    padding: 0;
    margin-left: 140px;
    margin-right: -290px;
    margin-top: 100px;
    z-index: 1;
    text-align: center;

    @media (max-width:640px) {
        padding: 0px;
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        margin-inline-start: 6vw;
        margin-inline-end: 1vw;
    }
}

.section4 .mediumHeader {
    @media (max-width:640px) {
        font-weight: 500;
        font-size: 40px;
        line-height: 55px;
        padding-left: 0px;
        padding-right: 0px;
        color: #000000;
        margin-bottom: 0px;
        margin-top: 88px;
        z-index: 2;
        position: relative;
        margin-inline-start: -3vw;
    }
}

.section4 .mediumSubheader {
    @media (max-width:640px) {
        width: 95%;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #4C4C4C;
        padding-inline-start: 0px;
        padding-inline-end: 39px;
        margin-top: 30px;
    }
}

.section4 .smallGreenButton {
    @media (max-width:640px) {
        height: 65px;
        width: 276px;
        margin-inline-start: 0px;
    }
}

.section5 {
    height: 100vh;

    @media (max-width:640px) {
        margin-top: 70px;
        min-height: 100vh;
        height: auto;
    }
}

.section5 .subsection {
    padding-right: 100px;
    position: relative;
    top: 200px;

    @media (max-width:640px) {
        top: 0px;
        flex-direction: column-reverse;
        align-items: unset;
        width: 100vw;
        padding-right: 0px;
    }
}

.section5 .subsectionRtl {
    padding-right: 10px;
    padding-left: 270px;

    @media (max-width:640px) {
        padding-right: 0px;
        padding-left: 0px;
    }
}

.section5 .rapper {
    padding-right: 0;
    z-index: 1;
    width: 60vw;
    margin-inline-end: -13vw;

    @media (max-width:640px) {
        margin-top: 0px;
        width: auto;
        margin-inline-start: 6vw;
        margin-inline-end: 1vw;
        padding: 10px 0px;
        text-align: center;

    }
}

.section5 .rapperRtl {
    padding: 0;
    margin-right: 140px;
    margin-left: -290px;
    z-index: 1;
    margin-top: -200px;

    @media (max-width:640px) {
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        text-align: center;
        margin-inline-start: 6vw;
        margin-inline-end: 1vw;
    }
}

.section5 .mediumHeader {
    @media (max-width:640px) {
        font-weight: 500;
        font-size: 40px;
        line-height: 55px;
        padding-left: 0px;
        padding-right: 0px;
        color: black;
        margin-bottom: 0px;
        z-index: 2;
        position: relative;
        margin-inline-start: -3vw;
    }
}

.section5 .mediumSubheader {
    @media (max-width:640px) {
        width: 95%;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #4C4C4C;
        padding-inline-start: 0px;
        padding-inline-end: 39px;
        color: #33354E;
        margin-top: -60px;
    }
}

.section5 .smallGreenButton {
    @media (max-width:640px) {
        height: 65px;
        width: 276px;
        margin-inline-start: 0px;
    }
}

.imgSection5 {
    @media (max-width:640px) {
        margin-inline-start: -145px;
    }
}

.imgSection5mobile {
    @media (max-width:640px) {
        margin-inline-start: -10px;
        width: auto;
        margin-top: -100px;
    }
}

.section7 {
    height: 22vh;

    @media (max-width:640px) {
        height: auto;
        min-height: 18vh;
    }

}

.section7 .mediumHeader {
    @media (max-width:640px) {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #4C4C4C;
        font-weight: 500;
    }
}

.section7 .image {
    width: 98.7vw;
    position: relative;
    top: 100px;
}

.section6 {
    height: 100vh;
    padding-top: 13vh;
    padding-left: 200px;
    padding-right: 200px;

    @media (max-width:640px) {
        padding: 10px;
        min-height: 100vh;
        height: auto;
    }
}

.section6 .rapper {
    padding-left: 0px;
    max-width: 100%;
    position: relative;
    top: 30vh;

    @media (max-width:640px) {
        margin-inline-start: 0vw;
        padding: 10px 0px;
        text-align: center;
        padding-top: 5vh;
        top: 0vh;
        margin-top: 0vh
    }
}

.section6 .text1 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 140px;
    line-height: 200px;
    color: #F5F3EE;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #45745E;

    @media (max-width:640px) {
        font-weight: 600;
        font-size: 83px;
        line-height: 80px;
    }
}

.section6 .text2 {
    padding-inline-start: 140px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 140px;
    line-height: 200px;
    color: #F5F3EE;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #45745E;

    @media (max-width:640px) {
        font-weight: 600;
        font-size: 50px;
        line-height: 65px;
        padding: 0;
        margin-bottom: 40px;
    }
}

.section6 .textRtl {
    font-size: 137px;

    @media (max-width:640px) {
        font-size: 64px;
    }
}

.section6 .text3 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: #4C4C4C;

    @media (max-width:640px) {
        font-weight: 500;
        font-size: 28px;
        line-height: 38px;
        letter-spacing: -1px;
        color: #33354E;
        width: 95vw;
    }
}

.section6 .text4 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #4C4C4C;
    opacity: 0.7;
    padding-top: 20px;
    padding-inline-end: 400px;
    width: 65vw;
    margin-bottom: -15px;

    @media (max-width:640px) {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #4C4C4C;
        width: 102%;
    }
}

.section6 .smallGreenButton {
    @media (max-width:640px) {
        height: 65px;
        width: 276px;
        margin-inline-start: 9px;
    }
}

.section7 .mediumHeader {
    padding-inline-start: 5vw;

    @media (max-width:640px) {
        font-size: 30px;
        line-height: 38px;
        color: #33354E;
        padding-inline-start: 0vw;
    }
}

.section8 {
    text-align: center;
}

.section8 .text1 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 130px;
    line-height: 55px;
    text-align: center;
    color: #33354E;

    @media (max-width:640px) {
        font-weight: 500;
        font-size: 32px;
        line-height: 15px;
        padding: 5px;
    }
}

.section8 .text2 {
    padding-top: 74px;
    padding-bottom: 150px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 97px;
    text-align: center;
    color: #33354E;

    @media (max-width:640px) {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        padding: 5px;
        padding-bottom: 60px;
    }
}

.section9 {
    height: 100vh;
    width: 100%;
    margin-bottom: 200px;

    @media (max-width:640px) {
        height: auto;
        margin-bottom: 24px;
    }
}

.section9 .flex {
    display: flex;
    font-family: 'Rubik';
    font-style: normal;
    width: 100%;
    height: 100vh;

    @media (max-width:640px) {
        flex-direction: column-reverse;
        height: auto;
    }
}

.section9 .video {
    position: relative;
    background-color: #33354E;
    width: 100%;
    padding-top: 15%;
    padding-inline-start: 3vw;
    text-align: center;
    height: 70vh;

    @media (max-width:640px) {
        height: 55vh;
        padding: 10px;
        padding-top: 15%;
        align-items: center;
        display: flex;
    }
}

.section9 .videoCard {
    width: 85%;
    padding: 5%;
    height: 100%;

    @media (max-width:640px) {
        width: 90%;
        padding: 2%;
        height: 106%;
    }
}

.section9 .video .title {
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    color: #FFFFFF;
    height: 20%;

    @media (max-width:640px) {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
    }
}

.section9 .video .subtitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 35px;
    height: 40%;

    @media (max-width:640px) {
        font-size: 14px;
        line-height: 24px;
    }

}

.playButton {
    direction: ltr !important;
    background: #33354E;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    width: 200px;
    padding-top: 12px;
    padding-bottom: 12px;
    justify-content: space-between;
}

.section10 {
    @media (max-width:640px) {
        height: 122vh;
    }
}

.section10 .subsection {
    position: relative;
    top: 100px;

    @media (max-width:640px) {
        flex-direction: column-reverse;
        align-items: unset;
        width: 100vw;
        padding-left: 0px;
        top: 0px;
    }
}

.section10 .rapperRtl {
    padding-bottom: 300px;

    @media (max-width:640px) {
        text-align: center;
    }
}

.section10 .mediumHeader {
    @media (max-width:640px) {
        font-weight: 500;
        font-size: 40px;
        line-height: 55px;
        color: black;
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 0px;
        margin-inline-start: -3vw;
    }
}

.section10 .mediumSubheader {
    @media (max-width:640px) {
        width: 95%;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #4C4C4C;
        padding-inline-start: 0px;
        padding-inline-end: 5px;
        margin-top: -145px;
    }
}

.section10 .smallGreenButton {
    @media (max-width:640px) {
        height: 65px;
        width: 276px;
        margin-inline-start: 0px;
    }
}

.section10 .rapper {
    @media (max-width:640px) {
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        padding: 10px 0px;
        margin-inline-start: 6vw;
        margin-inline-end: 1vw;
        text-align: center;
    }
}

.section10 .rapperRtl {
    @media (max-width:640px) {
        padding-right: 0px;
        padding-left: 0px;
        margin-top: 0px;
        margin-inline-start: 6vw;
        margin-inline-end: 1vw;
    }
}

.section11 {
    background-color: #ECE8DF;
    padding: 200px;
    padding-top: 15px;

    @media (max-width:640px) {
        padding: 10px;
        height: auto;
        background-color: #ECE8DF;
        padding-top: 100px;
    }
}

.section11 .lastDivider {
    margin-bottom: 50px;
}

.divider {
    @media (max-width:640px) {
        margin-bottom: 20px !important;
    }
}

.section11 .smallsubsection {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    position: unset;
    width: 760px;
    margin-bottom: 120px;
    margin-top: 120px;

    @media (max-width:640px) {
        margin-bottom: 40px;
        margin-top: 0px;
        width: 100%;
        text-align: center;
        flex-direction: column;
    }
}

.section11 .smallsubsection1 {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    position: unset;
    width: 760px;
    margin-bottom: 120px;
    margin-top: 120px;

    @media (max-width:640px) {
        margin-bottom: 40px;
        margin-top: 0px;
        width: 100%;
        text-align: center;
        flex-direction: column;
    }
}


.section11 .smallHeader {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 55px;
    color: #000000;
    margin-bottom: 20px;

    @media (max-width:640px) {
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
        margin-top: 10px;
    }
}

.section11 .smallrapper {
    margin-left: 35px;

    @media (max-width:640px) {
        margin-left: 0px;
    }
}

.section11 .mediumSubheader {
    @media (max-width:640px) {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #4C4C4C;
    }
}

.section11 .smallrapperRtl {
    margin-right: 35px;

    @media (max-width:640px) {
        margin-right: 0px;
        padding: 0px 15px;
    }

}

.section11 .rapperRtl {
    @media (max-width:640px) {
        padding: 0;
        margin-top: 0px;
        margin-inline-start: 0vw;
    }
}

.section11 .image {
    @media (max-width:640px) {
        height: 100px;
    }
}

.section11 .mediumHeader {
    width: 700px;

    @media (max-width:640px) {
        font-weight: 500;
        font-size: 40px;
        line-height: 55px;
        width: auto;
    }
}

.section11 .rapper {
    margin-top: 0px;

    @media (max-width:640px) {
        margin-inline-start: 0vw;
        margin-inline-end: 0vw;
    }
}

.section12 .subsection {
    top: -23px;

    @media (max-width:640px) {
        flex-direction: column;
    }
}

.section12 .subSectionCard {
    @media (max-width:640px) {
        display: flex;
        justify-content: center;
        padding-inline-start: 19px;
        padding-inline-end: 19px;
    }
}

.section12 .mediumHeader {
    @media (max-width:640px) {
        font-weight: 500;
        font-size: 40px;
        line-height: 55px;
        text-align: center;
        color: #000000;
        margin-bottom: 12px;
    }
}

.section12 .mediumSubheader {
    @media (max-width:640px) {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #4C4C4C;
        text-align: center;
        margin-bottom: 24px;
    }
}

.section13 {
    text-align: center;
    margin-bottom: 200px;

    @media (max-width:640px) {
        margin-bottom: 100px;
    }
}

.section13 .cardbtn {
    @media (max-width:640px) {
        width: 46%;
        display: flex;
        flex-direction: column;
    }
}

.section13 .title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    color: #33354E;
    padding-inline-end: 38px;

    @media (max-width:640px) {
        font-weight: 500;
        font-size: 40px;
        line-height: 55px;
        color: #000000;
        padding-inline-end: 0px;
        padding-bottom: 40px;
    }
}

.section13 .flexLine {
    display: flex;
    justify-content: center;
}

.section13 .table {
    padding-inline-start: 225px;
    padding-inline-end: 225px;

    @media (max-width: 640px) {
        padding-inline-start: 19px;
        padding-inline-end: 19px;
    }
}

.section13 .table .title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    text-align: start;
    color: #33354E;
    padding-inline-end: 0px;
}

.section13 .table .row {
    font-family: 'Rubik';
    font-style: normal;
    display: flex;
    justify-content: space-between;
    padding: 35px 0px;
    width: 75vw;


    @media (max-width: 640px) {
        flex-direction: column;
        width: 100%;
    }
}

.section13 .table .rowTitle {
    @media (max-width: 640px) {
        display: none;
    }
}

.section13 .table .rowBtn {
    display: flex;
    width: 35%;
    justify-content: space-between;

    @media (max-width: 640px) {
        width: auto;
    }
}

.section13 .smallrowBtn {
    position: relative;
    width: 45%;
    text-align: left;
}

.section13 .smallrowBtnRtl {
    position: relative;
    width: 45%;
    text-align: right;
}

.section13 .table .text {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #33354E;
    width: 60%;
    text-align: start;

    @media (max-width: 640px) {
        width: 100%;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: #4C4C4C;
        padding-bottom: 30px;
    }
}

.section13 .table .row .greenBtn {
    background: #8CC6AB;
    border-radius: 10px;
    border: none;
    text-align: start;
    padding-inline-start: 15px;
    padding-inline-end: 15px;
    cursor: context-menu;
    height: 60px;
    width: 45%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 640px) {
        width: 154px;
        height: 60px;
        text-align: center;
        display: inline;
    }
}

.section13 .table .row .redBtn {
    background: #FC9BA1;
    border-radius: 10px;
    border: none;
    text-align: start;
    padding-inline-start: 15px;
    padding-inline-end: 15px;
    cursor: context-menu;
    height: 60px;
    width: 45%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 640px) {
        width: 154px;
        height: 60px;
        text-align: center;
        display: inline;

    }
}

.smallBtnText {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;

    @media (max-width: 640px) {
        color: #000000;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        text-align: left;
        margin-bottom: 5px;
        margin-left: 3px;
    }
}

.smallBtnTextRtl {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;

    @media (max-width: 640px) {
        color: #000000;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        text-align: right;
        margin-bottom: 5px;
        margin-right: 3px;
    }
}

.section12 .card {
    padding: 20px 45px;
    background: #E9E7E0;
    border: 1px solid #C5C7D1;
    border-radius: 14px;
    width: 80px;
    height: 133px;
    text-align: center;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 15px;
    margin-top: 50px;

    @media (max-width:640px) {
        margin: 8px;
    }
}

.section12 .card .image {
    height: 75%;
    mix-blend-mode: multiply;
}

.section12 .verysmallHeader {
    color: #000000;
    font-size: 16px;
}

.section14 {
    height: 100vh;
    
    @media (max-width:640px) {
        height: auto;
        display: flex;
        position: relative;
        min-height: 90vh;
    }
}

.section14 .image {
    width: 96%;
    position: absolute;
    left: 0px;
    margin-left: 2%;
    border-radius: 24px;
}

.section14 .header {
    color: white;
    font-size: 60px;

    @media (max-width:640px) {
        font-weight: 700;
        font-size: 50px;
        line-height: 58px;
        text-align: center;
        letter-spacing: -1px;
        margin-left: 15vw;
        margin-top: 140px;
        margin-right: 15vw;
    }
}

.section14 .smallGreenButton {
    @media (max-width:640px) {
        width: 40vw;
    }
}

.section14 .card {
    position: relative;
    width: 100%;
    text-align: center;
    padding-top: 12vh;

    @media (max-width:640px) {
        padding-top: 0vh;
    }
}

.section15 .card {
    width: 31vw;
    height: 200px;
    background: #E9E7E0;
    border: 1px solid #C5C7D1;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 30px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 140px;

    @media (max-width: 640px) {
        width: auto;
        margin-bottom: 22px;
        margin-right: 19px;
        margin-left: 19px;
        padding: 20px;
    }
}

.section15 .card .mediumGreyHeader {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666
}

.section15 .header {
    color: #33354E;
    font-weight: 700;
    font-size: 40px;
    margin-top: 75px;
    margin-left: 26px;
    margin-right: 26px;
    margin-bottom: 70px;

    @media (max-width: 640px) {
        margin-top: 0px;
        margin-bottom: 50px;
        font-weight: 500;
        font-size: 40px;
        line-height: 55px;
        text-align: center;
        letter-spacing: -1px;
        text-shadow: none;
        margin-left: 0px;
        margin-right: 0px;
    }
}

.section15 .card .mediumHeader {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 24px;

    @media (max-width:640px) {
        color: #33354E;
    }
}

.section15 .card .mediumSubheader {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;

    @media (max-width:640px) {
        color: #33354E;
        margin-top: 16px;
    }
}

.section15 .titleCard {
    padding-left: 10px;
}

.section15 .titleCardRtl {
    padding-right: 10px;
}

.section17 {
    height: 770px;

    @media (max-width:640px) {
        height: 130vh;
    }
}

.section17 .image {
    width: 100%;
    position: absolute;
    left: 2px;

    @media (max-width:640px) {
        width: 94%;
        position: absolute;
        left: 2px;
        margin-left: 3%;
    }
}

.section17 .card {
    position: relative;
    padding-top: 12vh;
    padding-left: 15vw;
    padding-right: 15vw;
    width: 40%;

    @media (max-width:640px) {
        padding-inline-start: 19px;
        width: 90%;
        padding-inline-end: 19px;
        margin-top: 3vh;
        text-align: center;
    }
}

.section17 .store {
    @media (max-width:640px) {
        display: flex;
    }
}

.section17 .header {
    color: white;
    font-size: 60px;

    @media (max-width:640px) {
        font-weight: 700;
        font-size: 40px;
        line-height: 55px;
        text-align: center;
        text-shadow: none;
    }
}

.section17 .subheader {
    color: white;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;

    @media (max-width:640px) {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
    }
}

.section17 .icon {
    margin-top: 45px;
    margin-inline-end: 17px;

    @media (max-width:640px) {
        width: 150px;
        height: 47.45px;
        margin-top: 10px;
        margin-inline-end: 19px;
    }
}

.section18 {
    height: 330px;
    padding-top: 214px;

    @media (max-width:640px) {
        height: 27vh;
        padding-top: 20px;
    }
}

.section18 .subheader {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #33354E;
    width: 200px;
    margin-bottom: 24px;
}

.section18 .smallSubheader {
    font-weight: 400;
    font-size: 16px;
    color: #33354E;
    margin-bottom: 22px;
    margin-right: 40px;
    width: 350px;
    text-decoration: solid;
}

.section18 .card {
    padding-inline-start: 50px;

    @media (max-width:640px) {
        padding-left: 20px;

    }
}

.section18 .subsection {
    @media (max-width:640px) {
        display: block;
    }
}

.section18 .cardRtl {
    padding-inline-start: 50px;

    @media (max-width:640px) {
        padding-right: 20px;
    }
}

.section16 {
    padding: 150px 15vw 200px 15vw;
    text-align: start;

    @media (max-width: 640px) {
        padding: 120px 19px 0px 19px;
    }
}

.side {
    width: 50%;

    @media (max-width: 640px) {
        width: 100%;
    }
}

.side1 {
    @media (max-width: 640px) {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        align-items: center;
    }
}

.section16 .side1 {
    @media (max-width: 640px) {
        flex-direction: column;
    }
}

.plusMinusImg {
    width: 24px;
    padding-inline-end: 25px;
    cursor: pointer;

    @media (max-width: 640px) {
        width: 13px;
        padding-inline-end: 10px;
    }
}

.questionRow {
    display: flex;
    align-items: center;
    
    @media (max-width: 640px) {
        align-items: baseline;
    }
}

.largeTitle {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    color: #33354E;
    margin-bottom: 73px;

    @media (max-width: 640px) {
        font-weight: 500;
        font-size: 40px;
        line-height: 55px;
        text-align: center;
        letter-spacing: -1px;
        margin-bottom: 60px;
    }
}

.mediumTitle {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #000000;

    @media (max-width: 640px) {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
    }
}

.smallTitle {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #33354E;
    margin-bottom: 119px;

    @media (max-width: 640px) {
        display: none;
    }
}

.answer {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #4C4C4C;
    padding-inline-start: 49px;

    @media (max-width: 640px) {
        font-size: 16px;
        line-height: 24px;
        padding-inline-start: 19px;
    }
}

.questioDivider {
    margin-top: 30px;
    margin-bottom: 30px;
}

.animation {
    width: 200px;
    margin-top: 100px;
    margin-bottom: 32px;

    @media (max-width:640px) {
        width: 99px;
        margin-bottom: 12px;
    }
}

.confetti {
    position: absolute;
    margin-top: -240px;
    margin-bottom: 0px;
    margin-inline-start: -42px;

    @media (max-width:640px) {
        width: 50%;
        margin-inline-start: 0%;
    }

    @media (max-width:1245px) {
        margin-top: -280px;
    }
}

.dotsSetcion1 {
    width: 640px;
    top: 410px;
    z-index: -1;

    // margin-inline-start: -50%;
    @media (max-width:640px) {
        width: 95vw;
        top: 249px;
    }

}

.arrow {
    border-radius: 50%;
    z-index: 70000;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    inset: auto 20px 120px auto;
    position: fixed;
    width: 47px;
    height: 47px;
    bottom: 185px;
    background: #ffffff;
    border: 1px solid #42C6A5;
    box-sizing: border-box;
    text-align: center;
    display: grid;
    justify-content: center;
    align-items: center;

    @media only screen and(max-width:1245px) and(min-width:640px) {
        display: none;
    }

    @media (max-width: 640px) {
        display: none;
    }
}

.imgFace {
    @media (max-width: 640px) {
        display: none;
    }
}

.questionsImg {
    @media (max-width: 640px) {
        width: 100px;
        margin-bottom: 80px;
    }
}

.subsectionCol {
    @media (max-width: 640px) {
        flex-direction: column;
    }
}

.langAndBtn {
    @media (max-width: 640px) {
        display: none;
    }
}

.langMenuIcon {
    display: none;

    @media (max-width: 640px) {
        display: unset;
        width: 24px;
        height: 24px;
    }
}

.userIcon {
    display: none;

    @media (max-width: 640px) {
        display: unset;
        width: 24px;
        height: 24px;
    }
}

.langDiv {
    position: absolute;
    z-index: 70000;
    top: 8px;
    right: 260px;

    @media (max-width: 640px) {
        width:325px ; 
        height:40px ;
        padding: 10px;
        background-color: #E6E6E6;
        position:unset;
        margin-inline-start: 30px;
    }

}

.langDivRtl {
    position: absolute;
    z-index: 70000;
    top: 8px;
    left: 260px;

    @media (max-width: 640px) {
        width:325px ; 
        height:40px ;
        padding: 10px;
        background-color: #E6E6E6;
        position:unset;
        margin-inline-start: 30px;
        text-align: right;
    }
}

.logo1 {
    @media (max-width: 640px) {
        width: 30vw;
        margin-inline-start: 5vw;
    }
}
.topbarbody .logo1 {
    @media (max-width: 640px) {
        width: 35vw;
        margin-inline-start: 5vw;
    }
}
.whatsApp {
    position: fixed;
    right: 20px !important;
    width: 45px;
    height: 45px;
    bottom: 130px !important;
    z-index: 70000;
    inset: auto 20px 70px auto;

    @media (max-width:1025px) {
        width: 30px;
        height: 30px;
        bottom: 65px !important;
        right: 23px !important;
    }
}

.imgwhatsApp {
    height: auto;
    max-width: 100%;
    transition: all 200ms ease-in-out;
}

.imgSection3Mobile {
    @media (max-width:1025px) {
        margin-top: -80px;
    }
}

.grText {
    @media (max-width:1025px) {
        font-size: 47px;
    }
}

.mobileVideo {
    width: 100vw;
    height: 375px;
    background: #33354E;
    opacity: 0.8;
    position: relative;
    text-align: center;
    display: flex;
}

.section9 .titleMobile {
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    color: #000000;
    font-weight: 500;
    padding: 30px 7vw;
}

.section9 .subtitleMobile {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #4C4C4C;
    padding: 0px 4vw;
    margin-bottom: 100px;
}

.section9 .titleMobile2 {
    padding: 30px 5vw;
}

.section9 .mobileVideo .playImg {
    width: 80px;
    height: 80px;
    display: block;
    position: absolute;
    top: 147.5px;
    left: 40%;
    z-index: 2;
    cursor: pointer;
}
.menuCard{
    @media (max-width: 640px) {
        width:325px  !important;
        text-align: start;
        
    }
}
.langMenu{
    @media (max-width: 640px) {
        width:380px  !important;
        text-align: start;
        left: -5px !important;
    }
}
.langMenuRtl{
    @media (max-width: 640px) {
        width:380px  !important;
        text-align: start;
        left: -26px !important;
    }
}
.desktopVideo {
    width: 100%;
    background: #33354E;
}

.section9 .desktopVideo .playImg {
    width: 80px;
    height: 80px;
    display: block;
    position: absolute;
    margin-top: -55vh;
    left: 23vw;
    z-index: 2;
    cursor: pointer;
}

.section9 .desktopVideo .playImg2 {
    width: 80px;
    height: 80px;
    display: block;
    position: absolute;
    margin-top: -55vh;
    right: 23vw;
    z-index: 2;
    cursor: pointer;
}

.logoRow {
    display: flex;
    margin-inline-start: 50px;

    @media (max-width: 640px) {
        margin-inline-start: 0px;
        width: 141px;
    }
}

.topbarItem {
    position: relative;
    padding-inline-start: 20%;
    background: transparent;
    border: none;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: start;
    color: #010521;
    display: inline-flex;
    align-items: center;
    text-decoration: none;

    @media (max-width: 640px) {
        display: none;
    }
}

.topbarItemMobile {
    @media (max-width: 640px) {
        background: transparent;
        border: none;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: start;
        color: #010521;
        display: inline-flex;
        align-items: center;
        text-decoration: none;
    }
}

.topbarItemMobileDiv {
    @media (max-width: 640px) {
        z-index: 70000;
        width:325px ; 
        padding: 10px;
        background-color: #E6E6E6;
        opacity: 0.8;
        position:unset;
        margin-inline-start: 30px;
        margin-top: 10px;
        border: transparent solid 1px;
        border-radius: 6px;
        text-align: start;
    }

}

.topbarItemText {
    margin-inline-end: 10px;
    width: max-content;
}

.logo2 {
    width: 3vw;
    height: 3vw;
    margin-inline-end: 10%;

    @media (max-width: 640px) {
        width: 10vw;
        height: 10vw;
        margin-inline-start: 5vw;
    }
}