@import url("https://fonts.googleapis.com/css?family=Rubik:500,400,700");

.inputText {
    padding-top: 0.7%;
    height: 55px;
    width: 400px;
    border-radius: 8px;
    border: 1px solid #cccccc;
    // padding-left: 16px;
    margin-bottom: 2.5vh;
    font-family: Rubik;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: start;

    @media (max-width:640px) {
        height: 46px;
        width: 250px;
    }
}

.inputTextDisable {
    padding-top: 0.7%;
    height: 55px;
    width: 400px;
    border-radius: 8px;
    border: 1px solid #80808026;
    color: #928e8e26;
    // padding-left: 16px;
    margin-bottom: 2.5vh;
    font-family: Rubik;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: start;

    @media (max-width:640px) {
        height: 46px;
        width: 250px;
    }
}

input {
    border: none !important;
}

textarea:focus,
input:focus {
    outline: none;
}

.inputfiled {
    height: 25px;
    width: 90%;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: start;
    font-family: "Rubik";
    padding-inline-start: 16px;
    border-radius: 8px;
    background-color: #FFFFFF;

    @media (max-width:640px) {
        font-size: 12px;
        height: 25px;
    }
}

.smallinputfiled {
    height: 25px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: start;
    font-weight: 500;
    font-family: "Rubik";
    padding-left: 16px;
    border-radius: 8px;
    background-color: #FFFFFF;
    padding-inline-start: 16px;

    @media (max-width:640px) {
        font-size: 12px;
        height: 25px;
        width: 80%;
    }
}

.inputTextAreafiled {
    width: -webkit-fill-available;
    height: 150px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: start;
    font-weight: 500;
    font-family: "Rubik";
    padding-inline-start: 16px;
    background-color: #FFFFFF;
    border: none;
}

.inputfiled::placeholder {
    color: #999999;
    font-weight: normal;
}

.checkbox {
    // margin-top: 3vh;
    // margin-left: 34vh;
    transform: scale(1.8);
    border: 2px solid #555555;
    display: flex !important;

    @media (max-width:640px) {
        transform: scale(1.5);
    }
}

.checkbox:checked {
    filter: hue-rotate(310deg) brightness(127%);
}

.mediaText {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding: 11px;

    @media (max-width:640px) {
        font-size: 12px;
        padding: 10px;
    }

    // padding: 1vh;
}

.marginSide {
    @media (max-width:640px) {
        margin-right: 4px;
    }
}

.media {
    margin-top: 1vh;
    margin-left: 0vh;
    transform: scale(1);
}

.borderMedia {
    height: 33px;
    width: 33px;
    // margin-top: -4vh;
    text-align: center;
    border-radius: 6px;

    @media (max-width:640px) {
        height: 29px;
        width: 25px;
    }
}

.flex {
    display: flex;
}

#inputfiled {
    height: 49px;
    padding-inline-start: 16px;

    @media (max-width:640px) {
        height: 37px;
    }
}

.inputfiledBig {
    height: 49px;
    padding-left: 16px;

    @media (max-width:640px) {
        height: 37px;
    }
}

.react-date-picker__calendar {
    font-family: Rubik !important;
    border-radius: 14px !important;
    font-weight: 500 !important;
}

.react-date-picker__wrapper {
    border: none !important;
}

.react-date-picker__button svg {
    display: none !important;
}

.react-calendar__tile--active {
    background-color: #42C6A5 !important;
}

.inputfiled2 {
    height: 49px;
    padding-left: 16px;
    width: 320px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    font-family: "Rubik";
    border-radius: 8px;
    background-color: #FFFFFF;
}

.inputfiled2::placeholder {
    color: #999999;
    font-weight: normal;
}

.inputfiledSelect {
    height: 49px;
    padding-inline-start: 16px;

    @media (max-width:640px) {
        height: 37px;
    }
}

.smallInputText {
    height: 55px;
    padding-top: 0.7%;
    border-radius: 8px;
    border: 1px solid #cccccc;
    font-family: Rubik;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: start;
    // padding-inline-start: 16px;
    width: 20vw;
    margin-bottom: 3%;

    @media (max-width:640px) {
        height: 46px;
        width: 156px;
        margin-bottom: 15px;

    }
}

.smallInputTextDisable {
    height: 60px;
    padding-top: 0.7%;
    border-radius: 8px;
    border: 1px solid #80808026;
    color: #928e8e26;
    padding-left: 16px;
    margin-bottom: 2.5vh;
    font-family: Rubik;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;

    // padding-inline-start: 16px;
    @media (max-width:640px) {
        height: 40px;
        width: 156px;
        margin-bottom: 15px;
    }
}

.topBarRegistration {
    // flex: 1%;
    // width: 780px;
    // height: 25px;
    // padding: 20px;
    background: #ffffff;
    box-shadow: 0px 2px 3px rgba(100, 100, 100, 0.15);
    display: flex;
    text-align: start;
    align-items: center;

    @media (max-width:640px) {
        width: 100%;
    }
}

.topBarRegistration .item {
    flex-grow: 3;
    margin: 10px;
}

.topBarRegistration .itemButton {
    text-align: end;
}

.topBarRegistration .topBarRegistrationButton {
    border: 1px solid black;
    border-radius: 6px;
    height: 32px;
    width: 75px;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;

    @media (max-width:640px) {
        font-size: 12px;
        height: 25px;
        width: 65px;
    }
}

.topBarRegistrationTitle {
    font-weight: 500;
    font-size: 16px;
    text-align: center;

    @media (max-width:640px) {
        font-size: 14px;
    }
}

.sideMenuIcon {
    @media (max-width:640px) {
        height: 18px;
    }
}

.mt-2 ul {
    @media (max-width:640px) {
        padding: 15px;
    }
}

.bottomBarRegistration {
    // flex: 1%;
    // width: 720px;
    // height: 4vh;
    padding: 15px 19px 15px 18px;
    //padding: 16px 50px 16px 50px;
    display: flex;
    text-align: start;
    // position: absolute;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(100, 100, 100, 0.15);

    @media (max-width:640px) {
        padding: 11px 16px 11px 15px;
        width: 95%;
    }
}

.bottomBarRegistration .bottomBarRegistrationButton {
       height: 45px;
    width: 110px;
    border-radius: 8px;
    border: 1px solid black;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    font-size: 16px;
    font-weight: 500;

    @media (max-width:640px) {
        font-size: 12px;
        height: 35px;
        width: 90px;
    }
}

#buttonNextApprove {
    background: #42c6a5;
    border: none;
    color: white;
}

#buttonNextUnapprove {
    background: #ebf3ef;
    border: none;
    color: white;
}

.bottomBarRegistration .itemButton {
    text-align: end;
}

.bottomBarRegistration .item {
    flex-grow: 5;
}

.checkboxItem {
    flex-grow: 5;
    padding-top: 11px;
    display: flex;

    @media (max-width:640px) {
        padding-top: 4px;
    }
}

.checkboxItemRight {
    flex-grow: 0;
    padding-inline-end: 20px;
    text-align: end;
    padding-top: 16px;

    @media (max-width:640px) {
        padding-top: 11px;
    }
}

.inputItem {
    flex-grow: 5;
    // display: flex;
}

.inputItemRight {
    flex-grow: 0;
    text-align: right;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
}

.countrySelect {
    position: relative;
    z-index: 2000;
    height: auto;
    max-height: 180px;
    background-color: #ffffff;
    border: 1px solid #a9b9ca;
    box-sizing: border-box;
    box-shadow: 0px 12px 32px rgba(26, 26, 26, 0.24);
    border-radius: 4px;
    font-size: 14px;
    line-height: 40px;
    padding: 20px 15px 0px 15px;
    overflow-y: scroll;
    text-align: start;
    margin-top: 10px;

    @media (max-width:640px) {
        font-size: 12px;
        padding: 0px 15px 0px 15px;
    }
}

.countrySelect::-webkit-scrollbar {
    width: 8px;
}

.countrySelect::-webkit-scrollbar-thumb {
    background: #a9b9ca;
    border-radius: 4px;
}

.placeholderSelect {
    font-size: 14px;
    padding-top: 17px;
    line-height: 20px;
    font-family: Rubik;
    color: #999999;
    font-weight: normal;

    @media (max-width:640px) {
        font-size: 12px;
        padding-top: 12px;
    }
}

.valueSelect {
    color: black;
    font-weight: 500;
    font-size: 14px;
    padding-top: 4px;
    line-height: 20px;
    font-family: Rubik;
    width: 90%;
    padding-inline-start: 16px;
    text-align: start;

    @media (max-width:640px) {
        font-size: 12px;
        padding-top: 2px;
    }
}

.bigInputText {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 8px;
    height: 200px;
    width: 28vw;
    padding-top: 0.7%;
    margin-bottom: 2.5vh;
    font-family: Rubik;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: start;

    @media (max-width:640px) {
        font-size: 12px;
        width: 80vw;
    }
}

.smallPlaceholder {
    font-family: Rubik;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #999999;
    margin-top: 10px;
    padding-inline-start: 16px;

    // padding-left: 16px;
    @media (max-width:640px) {
        margin-top: 5px;
        font-size: 9px;
    }
}

.smallPlaceholderRtl {
    // padding-left: 0px;
    padding-inline-start: 16px;
    // padding-right: 16px;
}

.placeholder {
    text-align: start;
}

button {
    cursor: pointer;
}

.sideBarMenu {
    position: absolute;
    width: 23vw;
    height: 100vh;
    top: 0px;
    background: #ffffff;
    box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.4);
    z-index: 1000;
    padding-top: 30px;
    left: 0px;

    @media (max-width:640px) {
        width: 180px;
        padding-top: 24px;
    }
}

.sideBarMenuRtl {
    position: absolute;
    width: 23vw;
    height: 97vh;
    top: 0px;
    background: #ffffff;
    box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.4);
    z-index: 1000;
    padding-top: 3vh;
    margin-right: -10px;
    direction: rtl;
    text-align: start;

    @media (max-width:640px) {
        width: 40vw;
    }
}

.closeMenu {
    position: absolute;
    left: 0px;
    height: 18px;
    margin-left: 1vw;
    cursor: pointer;

    @media (max-width:640px) {
        height: 14px;
    }
}

.titleMenu {
    font-weight: 500;
    text-align: center;
    // margin-bottom: 1vh;
    margin-bottom: 3vh !important;
    width: 100%;
    left: -3vh;

}

.subMenu,
.menuItem,
.titleMenu {
    font-size: 14px;
    font-family: Rubik;

    @media (max-width:640px) {
        font-size: 12px;
        margin-bottom: 0;
    }
}

.wrapHeaderMenu {
    border-bottom: solid 1px #dee2e6;
}

.subMenu {
    margin-bottom: 5vh;
    // margin-top: 5vh;
    cursor: pointer;

}

.menuItem {
    margin-bottom: 4vh;
    margin-top: 4vh;
    cursor: pointer;
}

.sideBarMenu {
    padding-inline-start: 0px !important;
}

.arrowMenu {
    margin-right: 1vw;
    margin-left: 1vw;
}

.currentMenuItem {
    background: white;
    border: 1px solid #33354e;
    border-radius: 6px;
    padding-left: 1vw;
    width: 15vw;
    margin-left: -1vw;

    @media (max-width:640px) {
        width: auto;
        padding: 3px;
        font-size: 12px;
    }

}

.currentSubMenu {
    background: #f6f5fb;
    border: 1px solid #33354e;
    border-radius: 6px;
    padding-left: 1vw;
    width: 17vw;
    margin-left: -1vw;

    @media (max-width:640px) {
        width: auto;
        padding: 3px;
        font-size: 12px;
    }
}


.iconStyle {
    // position: absolute;
    // margin-top: -37px;
    // margin-left: 300px;
    color: #C5C7D1;
    font-size: 24px;
    padding: 11px;

    @media (max-width:640px) {
        font-size: 14px;
    }
}

.iconSelect {
    margin-top: -7px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
}

#iconPosition {
    margin-top: -20px;
}

.whatsApp {
    position: fixed;
    right: 14px;
    width: 63px;
    height: 63px;
    bottom: 66px;
    z-index: 70000;
}

.imgwhatsApp {
    height: auto;
    max-width: 100%;
}

.pinCode {
    width: fit-content;
    direction: ltr;
}

.caretDownIcon {
    margin-right: 7px;
    width: 15px;
}
